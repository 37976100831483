export default {
  methods: {
    /* 根据name跳转页面 */
    goPage(name) {
      this.$router.push({
        name: name
      });
    },
    /* 返回上一页 */
    goBack() {
      this.$router.back();
    },
    /* 将图片转为 base64 格式 */
    getBase64(file) {
      return new Promise(function(resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function() {
          imgResult = reader.result.split(',')[1];
        };
        reader.onerror = function(error) {
          reject(error);
        };
        reader.onloadend = function() {
          resolve(imgResult);
        };
      });
    },
  }
}