<template>
  <div>
    <!-- 输入框 input -->
    <el-form-item v-if="item.type === 'text'" :label="item.label" :prop="item.key" :style="`display:`+ item.display">
      <el-input
          v-model="form[item.key]"
          :placeholder="item.placeholder || '请输入'"
          clearable
          :readonly="readonly || item.readonly"
          :disabled="readonly || item.disabled"
          :show-password="item.showPswd"
          @input="handleInput(item.label, form[item.key])"
      >
        <el-button slot="append" v-if="item.slot" :icon="item.slot" @click="slotClick(item.label, item.fun)"></el-button>
      </el-input>
    </el-form-item>
    <!-- 联想输入框 autocomplete-->
    <el-form-item v-if="item.type === 'autocomplete'" :label="item.label" :prop="item.key" :style="`display:`+ item.display">
      <el-autocomplete
          v-model="form[item.key]"
          :placeholder="item.placeholder || '请输入'"
          clearable
          :debounce="600"
          :fetch-suggestions="querySearch"
          :trigger-on-focus="false"
          :highlight-first-item="true"
          @select="handleSelect"
          @change="handleChange(item.label, form[item.key])"
          style="width: 100%"
      >
        <el-button slot="append" v-if="item.slot" :icon="item.slot" @click="slotClick(item.label, item.fun)"></el-button>
        <template slot-scope="{ item }">
          <div class="fs-16" style="text-overflow: ellipsis;overflow: hidden;">{{ item.header }}</div>
          <span class="tc-weak">{{ item.taxid }}</span>
        </template>
      </el-autocomplete>
    </el-form-item>
    <!-- 范围输入框 -->
    <el-form-item v-if="item.type === 'textDouble'" :label="item.label" :prop="item.key" :style="`display:`+ item.display">
      <el-input
          v-model="form[item.keyS]"
          placeholder="请输入"
          clearable
          :readonly="readonly"
          style="width: 47%"
          @input="handleInput(item.label, form[item.keyS])"
      ></el-input>
      <div class="display--inline-block tc" style="width: 6%">-</div>
      <el-input
          v-model="form[item.keyE]"
          placeholder="请输入"
          clearable
          :readonly="readonly"
          style="width: 47%"
          @input="handleInput(item.label, form[item.keyE])"
      ></el-input>
    </el-form-item>
    <!-- textarea -->
    <el-form-item v-if="item.type === 'textarea'" :label="item.label" :prop="item.key" :style="`display:`+ item.display">
      <el-input
          type="textarea"
          :autosize="item.autosize"
          placeholder="请输入"
          :readonly="readonly"
          :disabled="readonly"
          v-model="form[item.key]">
      </el-input>
    </el-form-item>
    <!-- 单选框 radio -->
    <el-form-item v-if="item.type === 'radio'" :label="item.label" :prop="item.key" :style="`display:`+ item.display">
      <el-radio-group v-model="form[item.key]" :disabled="readonly||item.disabled">
        <el-radio
            v-for="(i, ind) in item.list"
            :key="ind"
            :label="i.label"
            @change="handleChange(i.title, form[item.key])"
        >{{ i.title }}</el-radio>
      </el-radio-group>
    </el-form-item>
    <!-- 复选框 checkbox -->
    <el-form-item v-if="item.type === 'checkbox'" :label="item.label" :prop="item.key" :style="`display:`+ item.display">
      <el-checkbox-group
          v-model="form[item.key]"
          :disabled="readonly"
          @change="handleChange(item.label, form[item.key])">
        <el-checkbox
            v-for="(i, ind) in item.list"
            :key="ind"
            :label="i.label">{{ i.title }}</el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <!-- 下拉框 select -->
    <el-form-item v-if="item.type === 'select'" :label="item.label" :prop="item.key" :style="`display:`+ item.display">
      <el-select
          v-model="form[item.key]"
          :placeholder="item.placeholder || '请选择'"
          :clearable="item.clearable!==false"
          :disabled="readonly || item.disabled"
          :multiple="item.multiple"
          :loading="item.loading"
          @focus="handleFocus(item.label, form[item.key])"
          @change="handleChange(item.label, form[item.key])"
          collapse-tags
          filterable>
        <el-option
            v-for="i in item.options"
            :key="i.value"
            :label="i.label"
            :value="i.value">
        </el-option>
      </el-select>
    </el-form-item>
    <!-- 日期选择器 date year month week -->
    <el-form-item v-if="item.type === 'datePicker'" :label="item.label" :prop="item.key"
                  :style="`display:`+ item.display">
      <el-date-picker
          :type="item.typeName"
          :placeholder="'请选择' + (item.placeholder ? item.placeholder : '')"
          :readonly="readonly"
          :disabled="readonly"
          v-model="form[item.key]"
          :value-format="item.valueFormat"
          style="width: 100%;"
          @change="handleChange(item.label, form[item.key])"
      ></el-date-picker>
    </el-form-item>
    <!-- 日期时间选择器 datetime -->
    <el-form-item v-if="item.type === 'datetimePicker'" :label="item.label" :prop="item.key"
                  :style="`display:`+ item.display">
      <!-- PS：datetimePicker需要进行额外格式化，使用 handleChange 方法结合 dateFormat 函数 -->
      <el-date-picker
          type="datetime"
          :placeholder="item.placeholder || '请选择日期时间'"
          :readonly="readonly"
          :disabled="item.disabled"
          v-model="form[item.key]"
          @change="handleChange(item.label, form[item.key])"
          style="width: 100%;"
      ></el-date-picker>
    </el-form-item>
    <!-- 日期时间范围选择器 XX至XX monthrange daterange datetimerange -->
    <el-form-item v-if="item.type === 'datetimerangePicker'" :label="item.label" :prop="item.key"
                  :style="`display:`+ item.display"
                  style="height: 28px;">
      <el-date-picker
          :type="item.typeName"
          range-separator="至"
          :start-placeholder="item.startPlaceholder"
          :end-placeholder="item.endPlaceholder"
          :readonly="readonly"
          v-model="form[item.key]"
          :value-format="item.valueFormat"
          :style="'width:' + item.width"
          @change="handleChange(item.label, form[item.key])"
      ></el-date-picker>
    </el-form-item>
    <!-- button -->
    <el-form-item v-if="item.type === 'button'" :style="`margin-top:` + item.mt">
      <el-button
          :loading="item.loading"
          :type="item.buttonType"
          :style="`width:` + item.width"
          @click="handleClick(item.label, item)"
      >{{ item.label }}</el-button>
    </el-form-item>
    <!-- 插槽 -->
    <el-form-item v-if="item.type === 'slot'" :style="`margin-bottom:` + item.mb + `;margin-top:` + item.mt">
      <slot></slot>
    </el-form-item>
    <!-- 上传 图片 -->
    <el-form-item v-if="item.type === 'uploadImage'" :label="item.label" :prop="item.key"
                  ref="image"
                  :style="`display:`+ item.display">
      <el-upload
          ref="uploadFile"
          :action="item.action"
          accpet="image/jpg,image/jpeg,image/png"
          list-type="picture-card"
          :file-list="form.fileList"
          :auto-upload="item.autoUpload"
          :before-upload="beforeUpload"
          :on-change="handleAvatarSuccess"
          :on-progress="handleProgress"
      >
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{file}" style="width: 100%;height: 100%">
          <img
              class="el-upload-list__item-thumbnail"
              :src="file.url" alt=""
          >
          <span class="el-upload-list__item-actions">
        <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
        </div>
      </el-upload>
    </el-form-item>
    <el-dialog :visible.sync="dialogImageVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "formItem",
  props: {
    /* 表单内容 */
    form: {
      type: Object
    },
    /* 表单labelList */
    item: {
      type: Object
    },
    /* 是否只读，默认false */
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialogImageUrl: '',
      dialogImageVisible: false
    }
  },
  methods: {
    handleInput(label, value) {
      this.$emit('handleInput', label, value);
    },
    slotClick(label, value) {
      this.$emit('slotClick', label, value);
    },
    querySearch(queryString, callback) {
      this.$emit('querySearch', queryString, callback);
    },
    handleSelect(item) {
      this.$emit('handleSelect', item);
    },
    handleFocus(label, value) {
      this.$emit('handleFocus', label, value);
    },
    handleChange(label, value) {
      this.$emit('handleChange', label, value);
    },
    handleClick(label, value) {
      this.$emit('handleClick', label, value);
    },
    beforeUpload(file) {
      this.$emit('beforeUpload', file);
    },
    handleAvatarSuccess(file, fileList) {
      this.$emit('handleAvatarSuccess', file, fileList, this);
    },
    handleProgress(event, file) {
      this.$emit('handleProgress', event, file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogImageVisible = true;
    },
    handleRemove(file) {
      this.$emit('handleRemove', file);
    },
  }
}
</script>

<style scoped lang="scss">
.el-form-item {
  margin-right: 20px;
  &.el-form-item--mini {
    height: 28px;
  }
  ::v-deep {
    .el-date-editor .el-range-separator {
      min-width: 25px;
    }
  }
}
.el-radio {
  margin-right: 20px;
  ::v-deep {
    &:last-child {
      margin-right: 0;
    }
    .el-radio__label {
      padding-left: 5px;
    }
  }
}
.el-select {
  width: 100%;
}
</style>