export default {
  data() {
    return {
      headerName: '/wechatpublicaccount'
    }
  },
  methods: {
    /* 获取列表数据 */
    getInfo(url, params, dataList, loading, callBack) {
      if (!loading) loading = 'loading';
      this[loading].listLoading = true;
      this.$axios
        .post(this.headerName + url, params)
        .then((res) => {
          const {
            msg,
            code,
            data,
            count
          } = res;
          if (code !== 0) {
            this[loading].listLoading = false;
            if (code === 403) return;
            return this.$message.error(msg);
          }
          this[loading].listLoading = false;
          this[dataList] = data || res.result;
          if (callBack) callBack()
        })
        .catch((error) => {
          this.$message.error("获取信息失败");
          this[loading].listLoading = false;
        });
    },
    /* 删除数据 */
    deleteInfo(url, params, next, update) {
      let _this = this;
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
        beforeClose(action, instance, done) {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '删除中...';
            _this.$axios
              .post(_this.headerName + url, params)
              .then((res) => {
                const {
                  msg,
                  code
                } = res;
                if (code !== 0) {
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = '删除';
                  if (code === 403) return;
                  return _this.$message.error(msg);
                }
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = '删除';
                done();
              })
              .catch((error) => {
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = '删除';
                _this.$message.error("删除失败!");
              });
          } else {
            done();
          }
        }
      }).then(() => {
        if (next) next();
        if (update) update();
        this.$message.success("删除成功!");
      }).catch(() => {
        this.$message.info("已取消删除");
      });
    },
    /* 新增or修改数据 */
    addOrEditInfo(url, params, loading, next, update, nextParams) {
      if (!this.infoStatus) this.infoStatus = '获取';
      if (typeof loading === 'object') {
        this[loading[0]][loading[1]].loading = true;
      } else {
        this[loading] = true;
      }
      this.$axios
        .post(this.headerName + url, params)
        .then((res) => {
          const {
            msg,
            code
          } = res;
          if (code !== 0) {
            if (typeof loading === 'object') {
              this[loading[0]][loading[1]].loading = false;
            } else {
              this[loading] = false;
            }
            if (code === 403) return;
            return this.$message.error(msg || res.data.msg);
          } else {
            if (typeof loading === 'object') {
              this[loading[0]][loading[1]].loading = false;
            } else {
              this[loading] = false;
            }
            this.$message.success(this.infoStatus + "信息成功");
            if (next) next(nextParams);
            if (update) update();
          }
        })
        .catch((error) => {
          if (typeof loading === 'object') {
            this[loading[0]][loading[1]].loading = false;
          } else {
            this[loading] = false;
          }
          this.$message.error(this.infoStatus + "信息失败");
        });
    },
    /* 跳转至登录页面 */
    goLogin() {
      if (sessionStorage.userinfo) return true;
      this.$confirm('登录后才能使用完整功能', '提示', {
        confirmButtonText: '去登录',
        cancelButtonText: '暂不登录',
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        this.cacheFromInfo();
      }).catch(() => {});
    },
    cacheFromInfo() {
      sessionStorage.fromRoute = window.location.href;
      this.$router.push('/login');
    }
  }
}
