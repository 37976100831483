<template>
  <el-row>
    <el-col :span="8">
      <el-page-header @back="goBack"></el-page-header>
    </el-col>
    <el-col :span="8" class="py-30">
      <div class="fs-20 mb-30">
        <span v-if="$route.params.type.indexOf('header')>-1">
          <span v-if="$route.params.type==='header'">填写抬头信息</span>
          <span v-else>修改抬头信息</span>
        </span>
        <span v-else>开具发票</span>
      </div>
      <formList
          ref="ruleForm"
          :formLabel="formLabel"
          :form="form"
          formLabelWidth="100px"
          :rules="formRules"
          @querySearch="querySearch"
          @handleSelect="handleSelect"
          @handleInput="handleInput"
          @handleChange="handleChange"
          @handleClick="submit('ruleForm')"
          @slotClick="slotClick"
      ></formList>
    </el-col>
  </el-row>
</template>

<script>
import formList from "@/components/formList";
import publicFun from "@/mixins/public";
import interfaceWechat from "@/mixins/interfaceWechat";

export default {
  name: "invoice",
  mixins: [publicFun, interfaceWechat],
  components: {formList},
  data() {
    let taxidValidator = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        // if (!/^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/.test(value)) {
        if (!/^[A-Z0-9]{18}$/.test(value)) {
          callback(new Error('请输入正确的税号'));
        } else {
          callback();
        }
      }
    };
    let vm = this;
    return {
      infoStatus: '新增',
      form: {
        unionid: '',
        type: '0',
        id: '',
        header: '',
        taxid: '',
        address: '',
        telephone: '',
        bank: '',
        bankaccount: ''
      },
      formLabel: [
        {
          colSpan: 24,
          label: '发票类型',
          type: 'radio',
          key: 'type',
          disabled: this.$route.params.type === 'headerEdit',
          list: [
            { label: '0', title: '企业' },
            { label: '1', title: '个人' }
          ]
        },
        {
          colSpan: 24,
          label: '发票抬头',
          type: 'autocomplete',
          key: 'header',
          placeholder: '发票抬头',
          slot: 'el-icon-arrow-right',
          fun: '选择发票抬头'
        },
        {
          colSpan: 24,
          label: '税号',
          type: 'text',
          key: 'taxid',
          placeholder: '纳税人识别号',
          disabled: false
        },
        {
          colSpan: 24,
          label: '地址',
          type: 'text',
          key: 'address',
          disabled: false
        },
        {
          colSpan: 24,
          label: '电话号码',
          type: 'text',
          key: 'telephone',
          disabled: false
        },
        {
          colSpan: 24,
          label: '开户银行',
          type: 'text',
          key: 'bank',
          disabled: false
        },
        {
          colSpan: 24,
          label: '银行账号',
          type: 'text',
          key: 'bankaccount',
          disabled: false
        },
        {
          colSpan: 24,
          label: '提交',
          type: 'button',
          buttonType: 'primary',
          width: '100%',
          mt: '10px',
          loading: false
        }
      ],
      formRules: {
        type: [
          { required: true, message: '请输入发票类型', trigger: 'blur' }
        ],
        header: [
          { required: true, message: '请输入发票抬头', trigger: 'blur' }
        ],
        taxid: [
          { required: true, message: '请输入纳税人识别号', trigger: 'blur' },
          { validator: taxidValidator, trigger: 'blur' }
        ]
      },
      taxidValidatorRule: taxidValidator
    }
  },
  mounted() {
    if (this.$route.params.item !== '"noparams"' && this.$route.params.item !== 'carInfo') {
      let item = JSON.parse(this.$route.params.item);
      Object.keys(this.form).forEach(key => { this.form[key] = item[key]; });
      this.form.type = item.type || '0';
      /* 个人 处理 */
      if (item.type === '1') {
        this.formRules.taxid = [];
        this.formLabel[1].type = 'text';
        for (let i = 2; i < 7; i++) {
          this.formLabel[i].disabled = true;
        }
      }
    }
  },
  methods: {
    slotClick(label, value) {
      if (label === '发票抬头' && value === '选择发票抬头') {
        this.$router.push({
          path: '/makeInvoice/headerInfo',
          query: {
            from: 'invoice'
          }
        });
      }
    },
    handleInput(label, value) {
      if (sessionStorage.carInfo) {
        this.$route.params.item = 'carInfo';
      }
    },
    handleChange(label, value) {
      if (sessionStorage.carInfo) {
        this.$route.params.item = 'carInfo';
      }
      if (label === '企业' || label === '个人') {
        Object.keys(this.form).forEach(key => {
          if (key !== 'type') this.form[key] = '';
        });
        this.$nextTick(() => {
          this.$refs.ruleForm.$children[0].clearValidate();
        });
        if (label === '个人') {
          this.formRules.taxid = [];
          this.formLabel[1].type = 'text';
          for (let i = 2; i < 7; i++) {
            this.formLabel[i].disabled = true;
          }
        } else {
          this.formRules.taxid = [
            { required: true, message: '请输入纳税人识别号', trigger: 'blur' },
            { validator: this.taxidValidatorRule, trigger: 'blur' }
          ];
          this.formLabel[1].type = 'autocomplete';
          for (let i = 2; i < 7; i++) {
            this.formLabel[i].disabled = false;
          }
        }
      }
      if (label === '发票抬头' && !value) {
        Object.keys(this.form).forEach(key => {
          if (key !== 'type') this.form[key] = '';
        });
      }
    },
    querySearch(queryString, callback) {
      if (queryString === '') return callback([])
      this.$axios
          .post('/wechatpublicaccount/billing-information/list', {header: queryString})
          .then(res => {
            const {
              msg,
              result,
              code
            } = res;
            if (code !== 0) {
              return this.$message.error(msg);
            }
            return callback(result);
          })
          .catch(error => {});
    },
    handleSelect(item) {
      this.form = item;
    },
    submit(formName) {
      this.$refs[formName].$children[0].validate((valid) => {
        if (valid) {
          if (this.$route.params.item === '"noparams"') { /* 新增发票抬头 */
            this.infoStatus = '新增';
            this.form.unionid = JSON.parse(sessionStorage.userinfo).unionid;
            this.addOrEditInfo('/billing-information/insert', this.form, ['formLabel', 7], this.goPage, '', 'headerInfo');
          } else if (sessionStorage.carInfo) { /* 开具发票 */
            this.form.unionid = JSON.parse(sessionStorage.userinfo).unionid;
            let billingInformation = '';
            if (this.$route.params.item === 'carInfo') {
              billingInformation = this.form;
            } else {
              billingInformation = JSON.parse(this.$route.params.item);
            }
            let ticketInfoList = JSON.parse(sessionStorage.carInfo);
            let params = {
              billingInformation: billingInformation,
              ticketInfoList: ticketInfoList
            };
            if (this.$route.params.type === 'invoiceReopen') { /* 重开发票 */
              let param = {
                billingInformation: billingInformation,
                queryticketList: params.ticketInfoList
              };
              this.formLabel[7].loading = true;
              this.$axios
                  .post('/wechatpublicaccount/reopen-ticket/insertList', param)
                  .then(res => {
                    const {
                      code,
                      msg,
                      data
                    } = res;
                    if (code !== 0) {
                      this.formLabel[7].loading = false;
                      return this.$message.error(msg);
                    }
                    this.formLabel[7].loading = false;
                    sessionStorage.removeItem('carInfo');
                    this.$message.success("重开发票提交成功");
                    let _this = this;
                    setTimeout(() => {
                      _this.$router.push({
                        name: 'invoiceList',
                        params: {
                          status: '1'
                        }
                      });
                    }, 800);
                  })
                  .catch(err => {
                    this.formLabel[7].loading = false;
                    this.$message.error("重开发票失败");
                  });
            } else { /* 首次开票 */
              this.formLabel[7].loading = true;
              this.$axios
                  .post('/wechatpublicaccount/ticket/open', params)
                  .then(res => {
                    const {
                      code,
                      msg,
                      data
                    } = res;
                    if (code !== 0) {
                      this.formLabel[7].loading = false;
                      return this.$message.error(msg);
                    }
                    this.formLabel[7].loading = false;
                    sessionStorage.removeItem('carInfo');
                    this.$message.success("提交成功");
                    let _this = this;
                    setTimeout(() => {
                      _this.$router.push({
                        name: 'invoiceList',
                        params: {
                          status: '1'
                        }
                      });
                    }, 800);
                  })
                  .catch(err => {
                    this.formLabel[7].loading = false;
                    this.$message.error("开票失败");
                  });
            }
          } else { /* 修改发票抬头 */
            this.infoStatus = '修改';
            let page = this.$route.params.type.indexOf('header')>-1 ? 'headerInfo' : 'invoiceList';
            this.addOrEditInfo('/billing-information/update', this.form, ['formLabel', 7], this.goPage, '', page);
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>