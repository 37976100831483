<template>
  <el-form
      :model="form"
      :rules="rules"
      :size="formSize"
      :label-width="formLabelWidth"
      :label-position="labelPosition"
      class="clearfix"
      @submit.native.prevent
      style="margin-bottom: -18px">
    <!-- vertical 布局 -->
    <el-row v-if="direction === 'vertical'">
      <el-col v-for="col in formLabel" :span="24/formLabel.length">
        <formItem
            v-for="(item, index) in col"
            :key="index"
            :form="form"
            :item="item"
            :readonly="readonly"
            @handleInput="handleInput"
            @handleFocus="handleFocus"
            @handleChange="handleChange"
            @handleClick="handleClick"
            @handleAvatarSuccess="handleAvatarSuccess"
            @handleProgress="handleProgress"
        >
          <slot></slot>
        </formItem>
      </el-col>
    </el-row>
    <!-- horizontal 布局 -->
    <el-row v-else>
      <el-col v-for="(item, index) in formLabel" :key="index" :span="item.colSpan">
        <formItem
            :form="form"
            :item="item"
            :readonly="readonly"
            @handleInput="handleInput"
            @slotClick="slotClick"
            @querySearch="querySearch"
            @handleSelect="handleSelect"
            @handleFocus="handleFocus"
            @handleChange="handleChange"
            @handleClick="handleClick"
            @beforeUpload="beforeUpload"
            @handleAvatarSuccess="handleAvatarSuccess"
            @handleProgress="handleProgress"
            @handleRemove="handleRemove"
        >
          <slot></slot>
        </formItem>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import formItem from "@/components/formItem";

export default {
  name: "formList",
  components: {formItem},
  props: {
    /* 布局方向 */
    direction: {
      type: String,
      default: 'horizontal'
    },
    /* vertical 列数 */
    column: {
      type: String
    },
    /* 表单内容 */
    form: {
      type: Object
    },
    formSize: {
      type: String,
      default: function () {
        return ''
      }
    },
    /* 表单label宽度 */
    formLabelWidth: {
      type: String
    },
    /* 表单label对齐方式 */
    labelPosition: {
      type: String,
      default: function () {
        return 'right'
      }
    },
    /* 表单labelList
    * horizontal时，直接排列对应对象
    * vertical时，数组套数组，一层数组对应显示列数，二层数组对应每列显示内容
    * */
    formLabel: {
      type: Array
    },
    /* 是否只读，默认false */
    readonly: {
      type: Boolean,
      default: false
    },
    /* 校验规则 */
    rules: {
      type: Object
    }
  },
  methods: {
    handleInput(label, value) {
      this.$emit('handleInput', label, value);
    },
    slotClick(label, value) {
      this.$emit('slotClick', label, value);
    },
    querySearch(queryString, callback) {
      this.$emit('querySearch', queryString, callback);
    },
    handleSelect(item) {
      this.$emit('handleSelect', item);
    },
    handleFocus(label, value) {
      this.$emit('handleFocus', label, value);
    },
    handleChange(label, value) {
      this.$emit('handleChange', label, value);
    },
    handleClick(label, value) {
      this.$emit('handleClick', label, value);
    },
    beforeUpload(file) {
      this.$emit('beforeUpload', file);
    },
    handleAvatarSuccess(file, fileList, _this) {
      this.$emit('handleAvatarSuccess', file, fileList, _this);
    },
    handleProgress(event, file) {
      this.$emit('handleProgress', event, file);
    },
    handleRemove(file) {
      this.$emit('handleRemove', file);
    }
  }
}
</script>

<style scoped lang="scss">
</style>